import styled from "@styles/themedComponents";

export const MenuListStyle = styled.nav`
  ul {
    display: grid;
    grid-template-columns: repeat(8, 85px);
    justify-content: space-between;
    align-items: start;
    row-gap: 15px;
    margin: 0 -2px;
  }
`;

export const MenuItemStyle = styled.li`
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }

    span {
      display: inline-block;
      width: 100%;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: -0.5px;
      text-align: center;
    }
  }
`;
