import styled from "@styles/themedComponents";

export const HomeListWrapperStyle = styled.div`
  > *:first-child {
    margin-top: 20px;
    margin-bottom: 32px;
  }

  > *:last-child {
    padding-top: 80px;
  }
`;
