import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import marketingService, { eventType } from "@amondz/marketing-service";
import BaseInnerLayout from "@views/common/hoc/BaseInnerLayout";
import ATypePage from "@components/hoc/ATypePage";
import { RootStateType } from "@store/modules";
import { AuthStateType } from "@store/modules/auth";
import gtm from "@lib/utility/gtm";
import HomeList from "@views/home/homeList/HomeList";

const HomeListWrapper = (): JSX.Element => {
  const { isLoggedIn, userAuthState } = useSelector<RootStateType, AuthStateType>((state: RootStateType) => state.auth);

  useEffect(() => {
    gtm.viewMainHome(isLoggedIn, userAuthState.data?.userId);

    marketingService(eventType.viewHome, { userId: userAuthState.data?.userId, isLoggedIn });
  }, [isLoggedIn, userAuthState.data?.userId]);

  return <HomeList />;
};

export default ATypePage(BaseInnerLayout(HomeListWrapper));
