import * as React from "react";
import { FC } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { ClickGtmEventLogging, GTM_EVENT } from "@amondz/gtm";
import { PAGE_NAME } from "@amondz/constants-page";
import { HomeMenuItemType } from "@amondz/types";
import Icon from "@components/common/Icon";
import { getUrlToLinkType } from "@lib/utility/linkType";
import { INTRO_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import useClickWidgetItem from "@lib/hooks/useClickWidgetItem";
import { HOME_MENU_WIDGET_ID } from "@constants/service/home/home";
import { MenuItemStyle, MenuListStyle } from "./style";

export interface IMenuListProps {
  menuList: HomeMenuItemType[];
}

const MenuList: FC<IMenuListProps> = ({ menuList }) => {
  const onClickWidgetItem = useClickWidgetItem();
  const router = useRouter();

  return (
    <MenuListStyle>
      <ul>
        {menuList.map((item, index) => {
          return (
            <MenuItemStyle key={index}>
              <ClickGtmEventLogging
                event={GTM_EVENT.CLICK_MENU}
                property={{ itemIndex: index + 1, pageName: PAGE_NAME[router.route] }}
              >
                <Link
                  href={getUrlToLinkType(item.linkType, item.linkUrl, item.id) || INTRO_PAGE_URL_PATH}
                  onClick={() => onClickWidgetItem(HOME_MENU_WIDGET_ID)}
                >
                  <Icon src={item.imgUrl} />
                  <span>{item.title}</span>
                </Link>
              </ClickGtmEventLogging>
            </MenuItemStyle>
          );
        })}
      </ul>
    </MenuListStyle>
  );
};

export default MenuList;
