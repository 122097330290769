import produce from "immer";
import { useQueryClient } from "@tanstack/react-query";
import { mainHomeQueryKeys, useMainHomeList } from "@amondz/react-query";
import { Response, HomeListStateType, WidgetType } from "@amondz/types";

interface UseHomeListReturnType {
  homeListState?: HomeListStateType;
  isLoading: boolean;
  isError: boolean;
  updateWidget: (widget: WidgetType) => void;
}

export const useHomeList = (): UseHomeListReturnType => {
  const queryClient = useQueryClient();
  const { data, isLoading, isError, isSuccess } = useMainHomeList();

  const updateWidget = (widget: WidgetType) => {
    queryClient.setQueryData<Response<HomeListStateType>>(mainHomeQueryKeys.list(), (prev) =>
      produce(prev, (draft) => {
        if (draft?.data?.widgetList)
          draft.data.widgetList = draft?.data?.widgetList.map((item) => (item.id === widget.id ? widget : item));
      }),
    );
  };

  return {
    homeListState: data?.data,
    isLoading,
    isError: isError || (isSuccess && data?.status !== 1),
    updateWidget,
  };
};
