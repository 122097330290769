import * as React from "react";
import { FC } from "react";
import { END } from "redux-saga";
import { GetServerSideProps } from "next";

import wrapper from "@store/index";
import BasePage from "@components/base/pages/BasePage";
import HomeListWrapper from "@views/home/homeList/HomeListWrapper";
import { getAmondzLoginEx, initializeCookie } from "@lib/utility/cookies";
import { userAuthAsync } from "@store/modules/auth";
import { INTRO_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";

/**
 * 홈 페이지
 *
 * layout 구성
 * BasePage - ATypeLayout - BaseInnerLayout - HomeListWrapper
 */
const Home: FC = () => {
  return (
    <BasePage url={INTRO_PAGE_URL_PATH}>
      <HomeListWrapper />
    </BasePage>
  );
};
export const getServerSideProps: GetServerSideProps = wrapper.getServerSideProps(async (context: any) => {
  // cookie 초기화 하기
  const cookie = initializeCookie(context.req);
  // cookie 에서 amondz token 꺼내오기
  const token = getAmondzLoginEx(cookie);
  if (token) {
    // 쿠키(토큰)이 있으면 유저 인증 시도
    context.store.dispatch(userAuthAsync.request());
    context.store.dispatch(END);
    await context.store.sagaTask.toPromise();
  }
});
export default Home;
