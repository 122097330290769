import * as React from "react";
import { WidgetType } from "@amondz/types";
import { useHomeList } from "@views/home/homeList/HomeListWrapper/useHomeList";
import WidgetSelector from "@views/home/homeList/WidgetSelector";
import MenuList from "@views/home/homeList/MenuList";
import WidgetList from "@views/home/homeList/WidgetList";
import { HomeListWrapperStyle } from "./style";

const HomeList = () => {
  const { homeListState, updateWidget } = useHomeList();

  if (!homeListState) return <></>;
  const [firstWidget, ...restWidget] = homeListState.widgetList;

  // todo: 모비두 변경 후 작업 예정
  // const { getJson } = useFirebaseRemoteConfig();
  // const videoWidgetConfig = getJson(REMOTE_CONFIG_KEY.SHORTFORM_WIDGET) as ShortformWidgetRemoteConfigType;

  // todo: 모비두 변경 후 작업 예정
  // const widgetVideo: VideoWidgetType = {
  //   id: "shoplive", // 임의의 id 부여
  //   mainTitle: videoWidgetConfig.title,
  //   subTitle: videoWidgetConfig.subTitle,
  //   widgetType: WIDGET.VIDEO,
  // };

  // todo: 모비두 변경 후 작업 예정
  // if (videoWidgetConfig.position !== -1) {
  //   // 코드상으로 0번부터 배열이 시작하지만 숏폼 위젯은 최상위 기획전을 1번 기준으로 시작한다(홈메뉴는 숫자에 포함되지 않음)
  //   restWidget.splice(videoWidgetConfig.position - 2, 0, widgetVideo);
  // }

  return (
    <HomeListWrapperStyle>
      <WidgetSelector
        index={0}
        widget={
          { ...firstWidget, mainTitle: null, subTitle: null, buttonText: null } as WidgetType & { mainTitle: null }
        }
        onLikeProduct={updateWidget}
      />
      <MenuList menuList={homeListState.homeMenuList} />
      <WidgetList widgetList={restWidget} onLikeProduct={updateWidget} isFirstWidgetExcluded={true} />
    </HomeListWrapperStyle>
  );
};

export default HomeList;
